// Import general.ts
import { WebcimesTooltip, WebcimesModal, WebcimesSelect,  axios, _t } from "@ts/general.ts";

// Export WebcimesTooltip, WebcimesModal, WebcimesSelect, axios, _t
export { WebcimesTooltip, WebcimesModal, WebcimesSelect, axios, _t };

// Import fo-top.ts
import "@ts/components/nav/fo-top.ts";

// Import fo-left.ts
import "@ts/components/nav/fo-left.ts";

// css
import "@css/fo.css";

// Wait for dom content loaded
document.addEventListener("DOMContentLoaded", function() {
});