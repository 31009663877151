// Import webcimes-tooltip
import "@/node_modules/webcimes-tooltip/dist/css/webcimes-tooltip.css";
import { WebcimesTooltip } from "webcimes-tooltip";

// css
import "@css/components/nav/fo-top.css";

// Wait for dom content loaded
document.addEventListener("DOMContentLoaded", function() {
    // Toogle nav-left
    document.querySelector(".nav-top__toggle")?.addEventListener("click", () => {
        document.querySelector(".nav-left")?.classList.toggle("nav-left--active");
    });

    // Open / Close nav item with childrens (expand button)
    document.querySelectorAll(".nav-top__link > .nav-top__expand").forEach((el: HTMLElement) => {
        // If nav link has no link (div)
        if(el.closest(".nav-top__link")?.tagName === "DIV") {
            el.closest(".nav-top__link")?.addEventListener("click", () => {
                el.closest(".nav-top__item")?.classList.toggle("nav-top__item--open");
            });
        }
        // If nav link has link (a)
        else {
            el.addEventListener("click", (e) => {
                e.preventDefault();
                el.closest(".nav-top__item")?.classList.toggle("nav-top__item--open");
            });
        }
    });
    
    // Close nav-top__item--open if click outside
    document.addEventListener("click", (e) => {
        let target = e.target as HTMLElement;
        if(!target.closest(".nav-top__subnav") && !target.closest(".nav-top__link")) {
            document.querySelectorAll(".nav-top__item--open").forEach((el: HTMLElement) => {
                el.classList.remove("nav-top__item--open");
            });
        }
    });

    // Tooltip profil
    new WebcimesTooltip({
        type: "button",
        element: document.querySelector(".nav-top__profil-container") as HTMLElement,
        setClass: "px-0 py-4",
        placement: "bottom",
    });
});