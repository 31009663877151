// css
import "@css/components/nav/fo-left.css";

// Wait for dom content loaded
document.addEventListener("DOMContentLoaded", function() {
    // Close nav-left on click on nav-left__close
    document.querySelector(".nav-left .nav-left__close")?.addEventListener("click", () => {
        document.querySelector(".nav-left")?.classList.remove("nav-left--active");
    });

    // Open / Close nav item with childrens (expand button)
    document.querySelectorAll(".nav-left__link > .nav-left__expand").forEach((el: HTMLElement) => {
        // If nav link has no link (div)
        if(el.closest(".nav-left__link")?.tagName === "DIV") {
            el.closest(".nav-left__link")?.addEventListener("click", () => {
                el.closest(".nav-left__item")?.classList.toggle("nav-left__item--open");
            });
        }
        // If nav link has link (a)
        else {
            el.addEventListener("click", (e) => {
                e.preventDefault();
                el.closest(".nav-left__item")?.classList.toggle("nav-left__item--open");
            });
        }
    });
    
    // On click outside
    document.addEventListener("click", (e) => {
        // Close nav-left if click outside
        let target = e.target as HTMLElement;
        if(!target.closest(".nav-left") && !target.closest(".nav-top__toggle")) {
            document.querySelector(".nav-left")?.classList.remove("nav-left--active");
        }

        // Close nav-left__item--open if click outside, except if nav-left__item--active is present
        if(!target.closest(".nav-left__subnav") && !target.closest(".nav-left__link")) {
            document.querySelectorAll(".nav-left__item--open:not(.nav-left__item--active)").forEach((el: HTMLElement) => {
                el.classList.remove("nav-left__item--open");
            });
        }
    });
});